import Vue from 'vue'
import Vuex from "vuex";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { createProvider } from './vue-apollo'
import "regenerator-runtime/runtime";
import IdleVue from "idle-vue";
import { readsession,requette,maj,calc_failure } from "./utils/functions";
import {calc_single,get_multiples,repaire_failure } from "./utils/failure_model";
//Echarts
import VueECharts from 'vue-echarts'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart,BarChart,PieChart } from 'echarts/charts'

import {
  LegendComponent,
  LegendScrollComponent,
  LegendPlainComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from 'echarts/components'
 import GmapCluster from 'vue2-google-maps/dist/components/cluster'
//Google
Promise.all([
  import('vue2-google-maps'),
  import('vue-google-charts'),
]).then(([VueGoogleMaps, GChart]) => {
  Vue.use(VueGoogleMaps,{
  load: {
    key: "AIzaSyARYpUgjYWTCDY_jqIb-so1dxUo3NXFhDI",
          libraries: 'places,drawing',
          loading:'async'
        },
         })
  Vue.use(GChart,{
 load: {
         packages:['corechart','columnchart','barchart'],
        },
  })
})

Vue.config.productionTip = false
Vue.prototype.$readsession = readsession();
Vue.prototype.$requette = requette;
Vue.prototype.$calc_failure = calc_failure;

Vue.prototype.$maj = maj;

Vue.prototype.$calc_single = calc_single;
Vue.prototype.$get_multiples = get_multiples;
Vue.prototype.$repaire_failure = repaire_failure;
const eventsHub = new Vue({
  router,
  store,
  vuetify,
  Vuex,
  apolloProvider: createProvider(),
  render: h => h(App),

}).$mount('#app')

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutes
  startAtIdle: false,
  LegendComponent,
  LegendScrollComponent,
  LegendPlainComponent,
  CanvasRenderer,
  LineChart,BarChart,PieChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
},

);
 Vue.component("VueECharts",VueECharts);
 Vue.component("GmapCluster", GmapCluster);

